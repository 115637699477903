import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//导入Element相关内容
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);
// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus);//使用Element框架
app.use(store).use(router).mount('#app')


