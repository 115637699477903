<template>
 <Router-View/>
</template>

<style>
#app{
  background-color: #ffffff;
}

</style>
